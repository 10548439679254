import {React, useState} from "react";
import { Link } from 'react-router-dom';
import { List, X } from "@phosphor-icons/react";
import About from "../pages/About";


// import Router from "next/router";

const MainHeader = ({ name, links }) => {

  name = "Olumide";
  links = [
    // { href: "#projects", label: "Projects" },
    { href: "/About", label: "About Me" },
    { href: "https://drive.google.com/file/d/1-m8R3eOyeObzpZVmLTexpCob5LN99tXc/view?usp=sharing", label: "Resume" },
    { href: "mailto:olumide.olajide@live.com", label: "Contact" },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="w-full flex flex-row fixed z-50 top-0 justify-between bg-gray-950 text-white items-center  font-sans h-16 px-16 max-sm:px-5">


      <div className="  justify-start flex items-baseline flex-row">
        <Link to={"/"}>
          <p className="font-black text-3xl max-sm:text-4xl">{name} </p> </Link> 
          <span className="bg-[#ED6A0D] w-2 h-2"></span>

      </div>

      {/* Mobile Menu */}
      <div className="w-full right-0 items-end justify-end  hidden max-sm:flex">
        {/* Hamburger Icon */}
        <button
          className="md:hidden p-2 bg-[#ED6A0D] focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          {isOpen ? (
            <X size={30}  className=" text-black" />
          ) : (
            <List  size={30} className=" text-black" />
          )}
        </button>

        {/* Foldable Menu */}
        <div
          className={`absolute w-[90%] top-16 bg-[#FAF9F5]  border-t-4 border border-t-[#ED6A0D] transform transition-transform duration-150 ease-in-out ${isOpen ? 'translate-y-0 ' : '-translate-y-[800px]'
            }`}
        >
          <ul className="flex flex-col space-y-8 text-lg font-bold p-4">
          {links.map((link, index) => (

            <li key={index}>
            <Link to={link.href} 
              className="block text-black hover:font-black hover:text-[#ED6A0D]"
            >
              {link.label}
            </Link>
          </li>

))}
            
          </ul>
        </div>
        </div>



        {/* Desktop Menu */}
        <nav>
          <ul className="flex space-x-6 max-sm:hidden">
            {links.map((link, index) => (
              <li key={index}>
                <Link to={link.href}
                  className="hover:text-gray-300 transition-colors text-sm duration-200"
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>

         


        </nav>


    </header>
  )
};

export default MainHeader;