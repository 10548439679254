import React from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import { BrowserRouter, Routes, Link, Route } from 'react-router-dom';
import HeaderMockup from '../assets/HeaderMockup.png';
import personal1 from '../assets/persona1.png';
import personal2 from '../assets/persona2.png';
import profpic1 from '../assets/profpic1.png';
import profpic2 from '../assets/profpic2.png';
import usertable1 from '../assets/usertable1.png';
import { Target } from "@phosphor-icons/react";
import infoarchi from '../assets/infoarchi.png';
import hifi from '../assets/hifi.png';
import lofi from '../assets/lofi.png';
import userflow from '../assets/userflow.png';
import Sticky from '../assets/Sticky.png';
import conversionrate from '../assets/conversionRate.png';
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";


const Project2 = () => {

    return (
        // <BrowserRouter>
        <div className="bg-[#FAF9F5]">

            <MainHeader />


            <div className="">




                <section className=" pt-24 px-36 max-sm:px-8" id="bannersection">

                    <div className="flex flex-col gap-5 justify-center items-center">
                        <h1 className="text-8xl max-sm:text-4xl">[product Name]</h1>
                        <p>E-commerce Personalization Platform.</p>
                        <p className="px-4 py-2 bg-gray-100 border uppercase text-sm">Product design</p>
                        <img src={HeaderMockup} />
                    </div>

                </section>
                <hr className="border-t border-gray-300 " />



                <section className=" mt-16 mb-10 px-36 max-sm:px-8 " id="overviewsection">
                    <div className="flex flex-col gap-5 justify-center items-center text-center">


                        <p className="uppercase font-black">Overview</p>
                        <p className="text-4xl font-bold text-[#85827A]">How We Brought <span className="text-[#222019]">Personality</span><br /> Back to <span className="text-[#222019]">Shopping</span></p>
                        <p className="w-[60%] max-sm:w-full">The task here was mainly designing a personalized e-commerce platform that used individual user data to offer tailored recommendations. The goal was to make shopping more engaging for customers while driving higher conversions.</p>
                    </div>

                    <div className="flex flex-row mt-16 justify-center items-center gap-10 max-sm:flex-col">

                        <div className="flex-col text-left w-1/2 max-sm:w-full">
                            <p className="text-4xl mb-8">Problem Statement</p>
                            <p>
                                Users often struggle to find relevant products quickly on e-commerce platforms due to generic recommendations and overwhelming options. Without a tailored experience, they feel frustrated and disengaged. The challenge was to design a personalized platform that anticipates user preferences and enhances discovery, making shopping efficient, engaging, and distinct from other solutions they've encountered.
                            </p>
                        </div>

                        <span className="w-full rounded-lg h-[400px] bg-gray-300"></span>

                    </div>

                    <hr className="border-t my-14 border-gray-300 " />

                    <div className="flex-row flex max-sm:flex-col  gap-8">
                        <div className=" flex-1 flex-col">

                            <div className="flex flex-row gap-2">
                                <span className="w-[1px] h-8 bg-black gap-2" /><p className="font-bold">My Role</p> <br />

                            </div>

                            <div className="flex flex-row gap-2">
                                <span className="w-2 h-8  gap-2" />
                                <p>Product Designer <br />
                                    Responsible for — User research, ideation, prototyping, UI design, Usability testing</p>

                            </div>
                        </div>

                        <div className=" flex-1 flex-col">
                            <div className="flex flex-row gap-2">
                                <span className="w-[1px] h-8 bg-black gap-2" /><p className="font-bold">Team</p> <br />

                            </div>

                            <div className="flex flex-row gap-2">
                                <span className="w-2 h-8  gap-2" />
                                <p>Sarah Thompson — Development<br />
                                    James McAllister — Data Science<br />
                                    Emily Chen — Marketing</p>

                            </div>
                        </div>

                        <div className=" flex-1 flex-col">
                            <div className="flex flex-row gap-2">
                                <span className="w-[1px] h-8 bg-black gap-2" /><p className="font-bold">Timeline</p> <br />

                            </div>

                            <div className="flex flex-row gap-2">
                                <span className="w-2 h-8  gap-2" />
                                <p>Calgary & Ottawa <br />
                                    January — April 2024</p>

                            </div>
                        </div>

                        <div className=" flex-1 flex-col">
                            <div className="flex flex-row gap-2">
                                <span className="w-[1px] h-8 bg-black gap-2" /><p className="font-bold">Tools</p> <br />

                            </div>

                            <div className="flex flex-row gap-2">
                                <span className="w-2 h-8  gap-2" />
                                <p>Figma, Anima, Spline, Miro, Rive app</p>

                            </div>
                        </div>






                    </div>



                </section>

                <hr className="border-t my-14 border-gray-300 " />

                <section className=" mt-16 mb-10 px-36 max-sm:px-8 " id="overviewsection">
                    <div className="flex flex-col gap-5 justify-center items-center text-center">


                        <p className="uppercase font-black">User Research</p>
                        <p className="w-[60%] max-sm:w-full">During the user research phase, we conducted user interviews with a diverse group of participants to understand their shopping behaviors, pain points, and expectations. This phase spanned four weeks, allowing us to gather detailed qualitative insights. The interview responses were analyzed and structured into empathy maps, highlighting users' thoughts, feelings, and needs. These insights informed the creation of detailed personas that represented key user groups. The personas were instrumental in mapping user journeys, visualizing their interactions with the platform, and identifying opportunities to enhance the experience through personalization. This process ensured a user-centered foundation for the platform’s design.</p>

                        <p className="uppercase font-black mt-10">Empathy Maps</p>
                        <span className="w-full h-[500px] bg-gray-200" />


                    </div>

                </section>
                <hr className="border-t my-14 border-gray-300 " />

                <section className=" mt-16 mb-10" id="personasection">
                    <div className="flex flex-col justify-center items-center text-center">
                        <div className="w-full h-auto">
                            <div className="absolute text-white ml-20 mt-12 ">
                                <p className="uppercase">Persona 1</p>
                            </div>
                            <img src={personal1} className="object-cover w-full" />

                        </div>


                        <div className="w-full h-auto">
                            <div className="absolute justify-end text-start text-white mt-12 ">
                                <div className="w-[60%] right-0  flex flex-col">
                                    <p className="uppercase">Persona 2</p>
                                    <p className="">Noah</p>



                                </div>
                            </div>
                            <img src={personal2} className="object-cover w-full" />

                        </div>

                    </div>
                </section>
                <section className="mt-16 mb-10 px-36 max-sm:px-8" id="journeymapsection">
                    <div className="flex flex-col mb-16">
                        <div className="flex flex-row items-center  mb-10">

                            <img src={profpic1} className="rounded-full mr-10 w-24 h-24 max-sm:w-16 max-sm:h-16 max-sm:mr-3" />
                            <div className="flex flex-col">
                                <p className="text-xl font-bold">Yura</p>
                                <span className="flex justify-center items-center flex-row max-sm:items-start max-sm:w-full"><Target /> <p className="max-sm:text-sm">Goal: Identified goal discovered during user interview</p></span>

                            </div>
                        </div>
                        <img src={usertable1} />

                    </div>

                    <div className="flex flex-col">
                        <div className="flex flex-row items-center  mb-10">

                            <img src={profpic2} className="rounded-full mr-10 w-24 h-24 max-sm:w-16 max-sm:h-16 max-sm:mr-3" />
                            <div className="flex flex-col">
                                <p className="text-xl font-bold">Noah</p>
                                <span className="flex justify-center items-center flex-row max-sm:items-start max-sm:w-full"><Target /> <p className="max-sm:text-sm">Goal: Identified goal discovered during user interview</p></span>

                            </div>
                        </div>
                        <img src={usertable1} />

                    </div>
                </section >

                <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8 " id="challenges">
                    <div className="flex flex-row w-[60%] mb-10 max-sm:flex-col max-sm:w-full max-sm:mb-5 ">
                        <div className="flex flex-col">

                            <p className="text-xl font-bold">Unique Challenges</p>
                            <ul className="list-disc">
                                <li>Balancing recommendation accuracy with diversity to avoid narrow product suggestions.</li>

                                <li>Designing intuitive browsing experiences for new vs. returning users with varying levels of data available.</li>

                                <li>Ensuring transparency about data usage to build user trust and compliance with privacy regulations.</li>
                            </ul>

                        </div>

                        <span className="w-[400px] flex rounded-lg h-auto bg-gray-300"></span>


                    </div>


                </section>

                <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8" id="designapproach">
                    <div className="flex flex-row w-[60%] items-center gap-10 mb-10  max-sm:flex-col max-sm:w-full">
                        <span className="w-[350px] h-[350px] flex rounded-lg  bg-gray-300"></span>

                        <div className="flex flex-col w-full">

                            <p className="text-xl font-bold">Design Approach</p>
                            <ul className="list-disc">
                                <li>Conducted market analysis on leading personalization features in e-commerce to identify high-impact features.</li>

                                <li>Developed user personas and journey maps to tailor experiences across different stages of the buying process.</li>

                                <li>Implemented data-driven design, where elements dynamically change based on user behavior and trends.</li>
                            </ul>

                        </div>

                    </div>
                </section>

                <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8" id="informationarchi">


                    <div className="flex flex-col justify-center items-center">

                        <p className="text-xl mb-5 font-bold">Information Architecture</p>
                        <img src={infoarchi} />

                    </div>


                </section>

                <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8" id="userflow">


                    <div className="flex flex-col justify-center items-center">

                        <p className="text-xl mb-5 font-bold">User Flow</p>
                        <img src={userflow} />

                    </div>


                </section>

                <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8" id="lofiscreen">


                    <div className="flex flex-col justify-center items-center">

                        <p className="text-xl mb-5 font-bold">Low Fidelity screens</p>
                        <img src={lofi} />

                    </div>


                </section>

                <section className="mt-16 mb-10 flex items-center justify-center" id="hifiscreen">


                    <div className="flex flex-col justify-center items-center">

                        <p className="text-xl mb-5 font-bold">High Fidelity Mockups</p>
                        <img src={hifi} />

                    </div>


                </section>

                <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8" id="prototype">


                    <div className="flex flex-col justify-center items-center w-full">

                        <p className="text-xl mb-5 font-bold">Prototype</p>
                        <span className="w-full h-[350px] flex rounded-lg  bg-gray-300"></span>


                    </div>


                </section>
                <hr className="border-t border-gray-300 " />


                <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8" id="usabilityTesting">


                    <div className="flex flex-col justify-center items-center text-center w-[60%] max-sm:w-full max-sm:text-left">

                        <p className="text-xl mb-5 font-bold">Usability Testing</p>
                        <p>After completing high-fidelity designs for the e-commerce personalization platform, a usability test was conducted with five participants. The test aimed to evaluate the app's functionality, focusing on how effectively personalized recommendations influenced purchase rates. The findings revealed a key issue: recommendations lacked relevance, leading to low user engagement and reduced conversion potential. Using an affinity diagram, we categorized and analyzed recurring problems, which included unclear recommendation logic, insufficient diversity in suggested products, and a lack of alignment with users’ browsing behaviors. These insights emphasized the need to refine the recommendation algorithm to boost its effectiveness and positively impact purchase rates</p>


                    </div>


                </section>

                <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8" id="affinity">


                    <div className="flex flex-col justify-center items-center">

                        <p className="text-xl mb-5 font-bold">Affinity Diagraming</p>
                        <div className="flex flex-row gap-10">
                            <div className="flex flex-col">
                                <p className="mb-3">Relevance <br />of Recommendation</p>
                                <img src={Sticky} />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-3">Ease of Navigation</p>
                                <img src={Sticky} />
                            </div>
                        </div>

                        <div className="flex flex-row gap-10 mt-10">
                            <div className="flex flex-col">
                                <p className="mb-3">Engagement with<br />
                                    Personalization Features</p>
                                <img src={Sticky} />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-3">Purchase Decision<br />
                                    Influence</p>
                                <img src={Sticky} />
                            </div>
                        </div>


                    </div>


                </section>



                <section className="mt-16 mb-10 flex flex-col px-36 max-sm:px-8" id="PrioritizedInsights">


                    <div className="flex flex-col justify-center mb-8 items-start w-[60%] max-sm:w-full">

                        <p className="text-xl mb-5 font-bold">Prioritized Testing</p>
                        <p className="font-bold">Priority 0</p>
                        <p>Based on the theme that: participants found the product recommendations irrelevant to their preferences, an insight is: the recommendation algorithm should prioritize products based on recent browsing and purchase history.</p>


                    </div>

                    <div className="flex justify-end mb-8">
                        <div className="flex flex-col items-end w-[60%]">


                            <p className="font-bold">Priority 1</p>
                            <p>Based on the theme that: participants felt overwhelmed by the number of recommendations displayed, an insight is: the platform should limit the number of recommendations per page to a manageable amount.</p>


                        </div></div>

                    <div className="flex flex-col justify-center mb-8 items-start w-[60%]">


                        <p className="font-bold">Priority 2</p>
                        <p>Based on the theme that: participants had difficulty understanding why certain products were recommended, an insight is: the platform should include brief explanations or tags indicating why a product was suggested (e.g., "Based on your recent search for sneakers").</p>


                    </div>

                    <div className="flex flex-row items-center max-sm:flex-col">

                        <div className="flex flex-col mr-5 justify-center w-[50%] max-sm:w-full">
                            <p className="text-3xl font-bold">A/B Testing</p>
                            <p className="text-xl font-bold">Results</p>
                            <ul className="list-disc">
                                <li>Button Color Impact: Changing the call-to-action button from blue to orange increased click-through rates by 15%.</li>
                                <li>
                                    Headline Clarity: Simplifying the homepage headline improved user engagement by 20%.
                                </li>
                                <li>Image Usage: Replacing text-heavy sections with relevant images led to a 12% higher conversion rate.</li>
                                <li>Navigation Menu Placement: Moving the navigation menu to the top of the screen reduced bounce rates by 8%.</li>
                                <li>
                                    Form Field Reduction: Reducing the number of required fields in a sign-up form increased completions by 25%
                                </li>
                            </ul>

                        </div>

                        <div className="flex flex-col items-center mr-3 max-sm:m-0 max-sm:mb-8">
                            <p className="text-8xl">A</p>
                            <span className="w-[200px] rounded-lg h-[400px] bg-gray-200" />
                        </div>

                        <div className="flex flex-col items-center">
                            <span className="w-[200px] h-[400px] rounded-lg bg-gray-200" />

                            <p className="text-8xl">B</p>
                        </div>




                    </div>


                </section>
                <hr className="border-t border-gray-300 " />


                <section className="mt-16 mb-10 flex flex-col items-center  px-36 max-sm:px-8" id="Impact">
                    <div className="flex flex-col  w-[60%] max-sm:w-full">
                        <p className="text-4xl font-bold text-center mb-5">Impact</p>


                        <p>The implemented features significantly enhanced user engagement and boosted conversion rates, transforming the e-commerce personalization platform into a more dynamic and user-centric experience:</p>
                        <ol className="list-decimal">
                            <li>Dynamic Product Bundles: By grouping related items based on user preferences, this feature made it easier for users to discover complementary products, resulting in higher upselling and cross-selling rates.

                            </li>
                            <li>Wishlist Reminders: Timely notifications for wishlisted items back in stock, experiencing price drops, or low in quantity drove users to act faster, reducing decision-making delays and increasing purchase frequency.

                            </li>
                            <li>Targeted Discounts and Promotions: Personalized discounts based on users’ purchase history and preferences created a sense of exclusivity, encouraging users to complete their transactions more often.

                            </li>
                            <li>Gamified Shopping: Adding badges and rewards for exploring new products incentivized users to interact more with the platform, fostering loyalty and increasing time spent on the app.

                            </li>
                        </ol>
                        <p>As a result of these enhancements, conversion rates experienced a consistent upward trend, as depicted in the accompanying graph. Together, these features addressed key pain points, improved user satisfaction, and drove measurable business outcomes.</p></div>


                </section>

                <section className="mt-16 bg-[#F3F1EB] mb-10 flex flex-col items-center  " id="conversion">

                    <div className="w-[70%] my-16 ">
                        <p className="font-bold mb-8"> Conversion rate in % (2024)</p>
                        <img src={conversionrate} />
                    </div>

                </section>

                <hr className="border-t border-gray-300 " />

                <section className="mt-16 mb-10 flex flex-col items-center px-36 max-sm:px-8 " id="Reflections">

                    <div className="flex flex-row justify-center items-center max-sm:flex-col">

                        <div className="flex flex-col mr-5  w-[70%] max-sm:w-full ">
                            <p className="text-xl font-bold">Reflections</p>
                            <p>Working on the e-commerce personalization platform was an invaluable experience, offering key insights into user-centered design and iterative problem-solving:</p>

                            <ol className="list-decimal">
                                <li>The Power of Data-Driven Insights: <br />Conducting thorough user research and usability testing was instrumental in uncovering pain points and guiding design decisions. Listening to users and analyzing their behavior reinforced the importance of empathy in creating meaningful experiences.</li>
                                <li>
                                    Personalization Requires Balance: <br /> While personalization is vital for engagement, it needs to feel natural and unobtrusive. The design iterations highlighted the need to strike a balance between relevance and user autonomy, ensuring recommendations enhanced the experience without feeling forced.        </li>
                                <li>Collaboration is Key: <br />Collaborating with stakeholders, developers, and data scientists enriched the design process. Cross-functional teamwork ensured alignment between user needs, business goals, and technical feasibility, ultimately leading to more impactful solutions.</li>

                                <li>Iterative Design Yields the Best Results: <br />The project underscored the importance of iteration. Initial assumptions about user needs were refined through testing and feedback, proving that flexibility and adaptability are crucial to successful design outcomes.</li>

                                <li>
                                    Gamification Enhances Engagement: <br />
                                    The introduction of gamified shopping elements taught a valuable lesson about the role of playful interaction in e-commerce. When thoughtfully implemented, such features can significantly boost engagement and loyalty.        </li>

                                <p>This project not only improved my ability to design for complex systems but also deepened my understanding of how personalization and thoughtful design choices can drive both user satisfaction and business success.</p>
                            </ol>
                        </div>

                        <span className="w-[200px] rounded-lg h-[400px] bg-gray-200 max-sm:mt-8" />



                    </div >




                </section >
                <hr className="border-t border-gray-300 " />

                <div className=" flex flex-row mx-14 justify-between max-sm:mx-2">


                    <Link to="/Project1">
                        <ArrowLeft size={60} className="" />
                    </Link>

                    <Link to="/Project3">
                        <ArrowRight size={60} />
                    </Link>

                </div>




                

            </div >
            <Footer />
        </div>

        // </BrowserRouter>
    );


}

export default Project2;