import React from "react";
import { Link } from 'react-router-dom';
import Project2 from "../pages/Project2";


// import Router from "next/router";

const Footer =() => {
    const currentYear = new Date().getFullYear();



    return (
        <div className="w-full flex flex-row bottom-0 bg-gray-950 text-white justify-between items-center border-t font-sans h-24 px-16 max-sm:px-10">


            <div className="justify-center w-full flex flex-row">
              <Link to={"/"}>
               <p className="text-gray-400 text-sm">&copy; {currentYear} Olumide Olajide,  All Rights Reserved</p> </Link> 
             
              
            </div>

           


        </div>
    )
};

export default Footer;