import React from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import { ArrowLeft, ArrowRight, Target } from "@phosphor-icons/react";
import { BrowserRouter, Routes, Link, Route } from 'react-router-dom';
import noQvendor1 from '../assets/noQvendor1.png';
import noQcustomer2 from '../assets/noQcustomer2.jpg';
import userresearch1 from '../assets/userresearch1.png';
import userresearch2 from '../assets/userresearch2.png';
import personal1 from '../assets/persona1.png';
import personal2 from '../assets/persona2.png';
import personal3 from '../assets/persona3.png';
import personal4 from '../assets/persona4.png';
import profpic1 from '../assets/profpic1.png';
import profpic2 from '../assets/profpic2.png';
import Aria from '../assets/Aria.png';
import Joshua from '../assets/Joshua.png';
import usertable1 from '../assets/usertable1.png';
import NOQ_3platforms from '../assets/NOQ_3platforms.png';
import NOQ_Orderconfirmed from '../assets/NOQ_OrderConfirmed.png';
import infoarchi from '../assets/infoarchi.png';
import hifi from '../assets/hifi.png';
import lofi from '../assets/lofi.png';
import userflow from '../assets/userflow.png';
import hifidesign from '../assets/hifidesign.jpg';
import IA_Vendorpath from '../assets/IA_VendorPath.jpg';
import IA_customerPath from '../assets/IA_customerPath.jpg';
import Sketch1 from '../assets/Sketch1.png';
import Sketch2 from '../assets/Sketch2.png';
import Sketch3 from '../assets/Sketch3.png';
import Sketch4 from '../assets/Sketch4.png';



const Project1 = () => {

    return (
        <div className="bg-gray-950 text-white">
            <MainHeader />


            <section className="pt-10 " id="bannersection">

                <div className="w-full flex h-[700px]">
                    <div className="absolute text-start text-white mt-12 ">
                        <div className="w-auto ml-16 mt-[60%] flex flex-col">
                            <h1 className="text-8xl font-black bg-black p-2 max-sm:text-4xl">No-Q</h1>
                            <p className="bg-black p-2 mt-1">Outdoor event marketplace platform.</p>



                        </div>
                    </div>
                    <img src={noQvendor1} className="object-cover w-full" />

                </div>

            </section>

            <section className=" mt-16 mb-10 px-36 max-sm:px-8 " id="overviewsection">
                <div className="flex flex-col gap-5 justify-center items-center text-center">

                    <p className="text-4xl font-bold text-[#85827A]">Transforming <span className="text-white">Outdoor</span><br /> Event <span className="text-white"> Experiences</span></p>


                    <p className="uppercase font-black">Overview</p>

                    <p className="w-[60%] text-left max-sm:w-full">NO-Q is an innovative online marketplace designed to enhance the outdoor event experience for vendors, attendees, and event organizers. <br />
                        The No-Q platform streamlines vendor operations, empowers attendees to order seamlessly from their phones, and provides event managers with actionable data insights. <br />
                        I took on multiple roles for this project, including lead designer, product manager and frontend developer.</p>
                </div>

                <div className="flex flex-row mt-16 justify-center items-center gap-10 max-sm:flex-col">

                    <div className="flex-col text-left w-1/2 max-sm:w-full">
                        <p className="uppercase font-black text-center mb-5">Problem Statement</p>

                        <p>
                            Outdoor events often face challenges such as long vendor lines, missed sales opportunities, and inefficient event management. Vendors struggle with managing orders during peak times, attendees are frustrated by wait times that reduce their enjoyment, and event organizers lack tools to monitor sales and improve the overall experience. There is a need for a solution that addresses these pain points by streamlining operations, enhancing customer convenience, and providing actionable insights for organizers.
                        </p>
                    </div>

                    <img src={noQcustomer2} className="w-full rounded-lg object-cover h-[400px]" />
                </div>

                <hr className="border-t my-14 border-gray-300 " />

                <div className="flex-row flex max-sm:flex-col  gap-8">
                    <div className=" flex-1 flex-col">

                        <div className="flex flex-row gap-2">
                            <span className="w-[1px] h-8 bg-black gap-2" /><p className="font-bold">My Role</p> <br />

                        </div>

                        <div className="flex flex-row gap-2">
                            <span className="w-2 h-8  gap-2" />
                            <p>Product Designer <br />
                                Responsible for — User research, ideation, prototyping, UI design, Usability testing</p>

                        </div>
                    </div>

                    <div className=" flex-1 flex-col">
                        <div className="flex flex-row gap-2">
                            <span className="w-[1px] h-8 bg-black gap-2" /><p className="font-bold">Team</p> <br />

                        </div>

                        <div className="flex flex-row gap-2">
                            <span className="w-2 h-8  gap-2" />
                            <p>Temi Olajide — Front-End Development<br />
                                Dayo Ajayi — Back-End Development<br />
                                </p>

                        </div>
                    </div>

                    <div className=" flex-1 flex-col">
                        <div className="flex flex-row gap-2">
                            <span className="w-[1px] h-8 bg-black gap-2" /><p className="font-bold">Timeline</p> <br />

                        </div>

                        <div className="flex flex-row gap-2">
                            <span className="w-2 h-8  gap-2" />
                            <p>2022 — 2024 </p>
                               

                        </div>
                    </div>

                    <div className=" flex-1 flex-col">
                        <div className="flex flex-row gap-2">
                            <span className="w-[1px] h-8 bg-black gap-2" /><p className="font-bold">Tools</p> <br />

                        </div>

                        <div className="flex flex-row gap-2">
                            <span className="w-2 h-8  gap-2" />
                            <p>Figma, Clickup, React, Tailwind, VSCode</p>

                        </div>
                    </div>






                </div>



            </section>

            <section className="flex flex-col mt-16  items-center bg-[#222019] text-white py-12 mb-10 max-sm:px-16" id="researchsection">
                {/* <div className="flex flex-col justify-center items-center"> */}

                <p className="uppercase font-black mb-3">User Research</p>



                <div className="flex flex-row mt-12 items-center justify-center gap-24 max-sm:flex-col max-sm:gap-20">

                    <div className="w-[40%] max-sm:w-full max-sm:items-center">
                        <h3 className=" font-bold">Objective</h3>
                        <p className="">
                            To understand the pain points, needs, and expectations of outdoor event vendors, attendees, and organizers
                            to design a platform that addresses their unique challenges and enhances the overall event experience.
                        </p>
                        <h3 className="mt-6 font-bold ">Research Methods</h3>
                        <ul className="list-disc list-inside  space-y-2">
                            <li>
                                Stakeholder Interviews:<br /> Conducted in-depth interviews with vendors (e.g., food trucks,
                                merchandise sellers) and event organizers to uncover operational challenges and user goals.
                            </li>
                            <li>
                                Surveys: <br />Distributed online surveys to event attendees, gathering insights on common
                                frustrations, purchasing preferences, and expectations for a seamless event experience.
                            </li>
                            <li>
                                Competitor Analysis:<br /> Analyzed existing event marketplace solutions to identify gaps and
                                opportunities for differentiation.
                            </li>
                            <li>
                                Observational Studies:<br /> Observed real-life event operations to identify bottlenecks,
                                including long queues and inefficient vendor management practices.
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-20 -my-10 max-sm:items-center">
                        <img src={userresearch1} className="object-cover w-[65%] max-sm:w-full " />
                        <img src={userresearch2} className="object-cover w-[65%] max-sm:w-full " />

                    </div>

                </div>




                {/* </div> */}

                <div>

                </div>
                {/* <div className="bg-[#222019] w-full h-[800px] max-sm:h-[1400px]" /> */}
                {/* </div> */}
            </section>



            <section className="flex flex-col mt-10 px-10 w-full justify-center items-center" id="keyfindingssection">
                <p className="uppercase font-black mb-5">Key Findings</p>


                <div>
                    <ul className="space-y-8">
                        <li>
                            <p>Vendors:</p>
                            <ul className="list-disc pl-5 space-y-1">
                                <li>Struggle to manage high-volume orders during peak times.</li>
                                <li>Miss potential sales due to long lines and slow payment processes.</li>
                                <li>Desire a simple, mobile-friendly system to streamline operations.</li>
                            </ul>
                        </li>

                        <li>
                            <p>Attendees:</p>
                            <ul className="list-disc pl-5 space-y-1">
                                <li>Frustrated by long wait times, leading to a reduced event experience.</li>
                                <li>Prefer cashless, digital payment options and the ability to pre-order.</li>
                                <li>Want access to a centralized marketplace for easy browsing of vendor offerings.</li>
                            </ul>
                        </li>
                        <li>
                            <p>Event Organizers:</p>
                            <ul className="list-disc pl-5 space-y-1">
                                <li>Need tools to monitor vendor performance and attendee trends in real time.</li>
                                <li>Require an efficient system to ensure smooth operations and high attendee satisfaction.</li>
                            </ul>
                        </li>
                    </ul>


                    <div>
                        <p className="uppercase font-bold mt-10">Impact on Design</p>
                        <p className="">
                            The research informed a user-centric design approach that prioritizes:
                        </p>
                        <ul className="list-disc list-inside  space-y-2">
                            <li>A seamless ordering process for attendees.</li>
                            <li>Vendor tools for managing orders and payments efficiently.</li>
                            <li>A centralized dashboard for event organizers to gain actionable insights.</li>
                        </ul>

                    </div>
                </div>



            </section >

            <section className=" mt-16 mb-10 hidden" id="personasection">
                <div className="flex flex-row justify-center items-center text-center max-sm:flex-col">
                    <div className="w-1/2 h-auto max-sm:w-full">
                        <div className="absolute text-white ml-20 mt-12 ">
                            <p className="uppercase">Persona 1</p>
                        </div>
                        <img src={personal3} className="object-cover w-full" />

                    </div>


                    <div className="w-1/2 h-auto max-sm:w-full">
                        <div className="absolute justify-end text-start text-white mt-12 ">
                            <div className="w-[60%] right-0  flex flex-col">
                                <p className="uppercase">Persona 2</p>
                                <p className="">Aria</p>



                            </div>
                        </div>
                        <img src={personal4} className="object-cover w-full" />

                    </div>

                </div>
            </section>
            <section className="mt-16 mb-10 px-36 hidden max-sm:px-8" id="journeymapsection">
                <div className="flex flex-col mb-16">
                    <div className="flex flex-row items-center  mb-10">

                        <img src={Aria} className="rounded-full mr-10 w-24 h-24 max-sm:w-16 max-sm:h-16 max-sm:mr-3" />
                        <div className="flex flex-col">
                            <p className="text-xl font-bold">Aria</p>
                            <span className="flex justify-center items-center flex-row max-sm:items-start max-sm:w-full"><Target /> <p className="max-sm:text-sm">Goal: Identified goal discovered during user interview</p></span>

                        </div>
                    </div>
                    <img src={usertable1} />

                </div>

                <div className="flex flex-col">
                    <div className="flex flex-row items-center  mb-10">

                        <img src={Joshua} className="rounded-full mr-10 w-24 h-24 max-sm:w-16 max-sm:h-16 max-sm:mr-3" />
                        <div className="flex flex-col">
                            <p className="text-xl font-bold">Joshua</p>
                            <span className="flex justify-center items-center flex-row max-sm:items-start max-sm:w-full"><Target /> <p className="max-sm:text-sm">Goal: Identified goal discovered during user interview</p></span>

                        </div>
                    </div>
                    <img src={usertable1} />

                </div>
            </section >

            <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8 " id="challenges">
                <div className="flex flex-col w-full mb-10 items-center justify-center max-sm:flex-col max-sm:w-full max-sm:mb-5 ">
                    <p className="uppercase mb-5 font-black ">Unique Challenges</p>

                    <div className="flex flex-row items-center max-sm:flex-col">

                        <ul className="list-decimal w-1/2 max-sm:w-full">
                            <li>
                                <strong>Catering to Diverse User Groups
                                </strong>
                                <ul className="list-disc pl-5 space-y-1">
                                    <li>
                                        <strong>Challenge: </strong> Designing a platform that effectively meets the needs of three distinct user groups: vendors, attendees, and event organizers, each with unique goals and pain points.</li>
                                    <li><strong>Solution: </strong>Developed role-specific interfaces with unique features for each user group while maintaining a consistent data flow:
                                        <ul className="list-disc pl-5">
                                            <li>Attendees: Streamlined ordering and payment</li>
                                            <li>Vendors: Order management and inventory tracking</li>
                                            <li>Event Managers: Real-time monitoring and analytics</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <img src={NOQ_3platforms} alt="No-Q apps" className="w-1/2 max-sm:w-full max-sm:mt-5" />



                    </div>


                    <div className="flex flex-row items-center mt-16 max-sm:flex-col">

                        <ul className="list-disc w-1/2 max-sm:w-full">
                            <li>
                                <strong>Queue Management
                                </strong>
                                <ul className="list-disc pl-5 space-y-1">
                                    <li>
                                        <strong>Challenge: </strong> Reducing the frustration of long lines at food stalls, merchandise vendors, and event attractions while ensuring vendors can efficiently manage high traffic periods.</li>
                                    <li><strong>Solution: </strong>Introducing a virtual queue system where attendees can place orders or book slots for services, receive real-time updates on order status, and get notified when their order or turn is ready.
                                        {/* <ul className="list-disc pl-5">
                                        <li>Attendees: Streamlined ordering and payment</li>
                                        <li>Vendors: Order management and inventory tracking</li>
                                        <li>Event Managers: Real-time monitoring and analytics</li>
                                        </ul> */}
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <img src={NOQ_Orderconfirmed} alt="No-Q apps" className="w-1/2 max-sm:w-full max-sm:mt-5" />

                    </div>




                </div>


            </section>

            <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8" id="designapproach">
                <div className="flex flex-row w-[60%] items-center gap-10 mb-10  max-sm:flex-col max-sm:w-full">
                    {/* <span className="w-[350px] h-[350px] flex rounded-lg  bg-gray-300"></span> */}

                    <div className="flex flex-col w-full">

                        <p className="uppercase mb-5 font-black">Design Approach</p>
                        <ul className="list-disc">
                            <li>Conducted market analysis on leading personalization features in e-commerce to identify high-impact features.</li>

                            <li>Developed user personas and journey maps to tailor experiences across different stages of the buying process.</li>

                            <li>Implemented data-driven design, where elements dynamically change based on user behavior and trends.</li>
                        </ul>

                    </div>

                </div>
            </section>

            <section className="mt-16 mb-10 flex items-center justify-center max-sm:px-8" id="userflow">


                <div className="flex flex-col justify-center w-full items-center">

                    <p className="uppercase mb-5 font-black">User Flows</p>
                    <img src={IA_Vendorpath} className="w-full object-cover" />
                    <img src={IA_customerPath} className="w-full object-cover" />

                </div>


            </section>



            <section className="mt-16 mb-10 flex items-center justify-center w-full max-sm:px-8" id="lofiscreen">


                <div className="flex flex-col justify-center w-[80%] items-center">

                    <p className="uppercase mb-5 font-black">Sketches and Initial notes</p>
                    <div className="flex flex-row gap-5 max-sm:flex-col">
                        <img src={Sketch1} />
                        <div className="flex flex-col items-center justify-center">
                            <img src={Sketch2} className="w-auto h-[400px] mb-10" />
                            <p className="w-[70%] ">
                                The initial sketches for NO-Q represent the early exploration of how the platform could address the needs of its primary users: vendors and attendees. These sketches focused on simplifying the ordering process for attendees and streamlining operations for vendors. While they didn’t translate 100% into the final design, they were a crucial step in shaping the product’s direction.
                                These early ideas highlight the iterative nature of product development, where feedback and evolving priorities drive meaningful changes.


                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row mt-10 gap-5">
                        <img src={Sketch3} />
                        <div className="flex flex-col items-center justify-center">
                            <img src={Sketch4} className="w-auto h-[400px] mb-10" />
                        </div>



                    </div>
                </div>


            </section>

            <section className="mt-16 mb-10 flex items-center justify-center" id="hifiscreen">


                <div className="flex flex-col justify-center w-full items-center">

                    <p className="text-xl mb-5 font-bold">High Fidelity Mockups</p>
                    <img src={hifidesign} className="w-full object-cover" />

                </div>


            </section>

            {/* <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8" id="prototype">


                <div className="flex flex-col justify-center items-center w-full">

                    <p className="text-xl mb-5 font-bold">Prototype</p>
                    <span className="w-full h-[350px] flex rounded-lg  bg-gray-300"></span>


                </div>


            </section>
            <hr className="border-t border-gray-300 " /> */}


            {/* <section className="mt-16 mb-10 flex items-center justify-center px-36 max-sm:px-8" id="usabilityTesting">


                <div className="flex flex-col justify-center items-center text-center w-[60%] max-sm:w-full max-sm:text-left">

                    <p className="text-xl mb-5 font-bold">Usability Testing</p>
                    <p>After completing high-fidelity designs for the e-commerce personalization platform, a usability test was conducted with five participants. The test aimed to evaluate the app's functionality, focusing on how effectively personalized recommendations influenced purchase rates. The findings revealed a key issue: recommendations lacked relevance, leading to low user engagement and reduced conversion potential. Using an affinity diagram, we categorized and analyzed recurring problems, which included unclear recommendation logic, insufficient diversity in suggested products, and a lack of alignment with users’ browsing behaviors. These insights emphasized the need to refine the recommendation algorithm to boost its effectiveness and positively impact purchase rates</p>


                </div>


            </section> */}


            {/* <div className=" flex flex-row mx-14 justify-between max-sm:mx-2">
                <ArrowLeft size={60} className="opacity-10" />

                <Link to="/Project2">
                    <ArrowRight size={60} />
                </Link>

            </div> */}

            <Footer />

        </div >
    );


}

export default Project1;