import React from 'react';
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';
import olu_pic from '../assets/olu_pic.jpg';
import onCamera from '../assets/onCamera.png';
import onCamera2 from '../assets/onCamera2.jpeg';
import atMountains from '../assets/atMountains.png';

const About = () => {
    return (
        <div className='bg-gray-900'>
            <MainHeader />

            <div className='flex flex-col bg-gray-900 mt-16 mb-28 mx-[30%] text-white justify-center items-center  h-auto max-sm:mx-10 '>

                {/* <div className="flex flex-col  w-[40%] mt-8 justify-center items-center max-sm:flex-col max-sm:w-full"> */}
                    <p className=' text-6xl text-center my-8  font-black max-sm:text-4xl'>Hi I'm Olumide, <br /> you can Call me Olu /O-Lou/</p>


                    {/* <div className="flex-col flex w-[40%] max-sm:w-full"> */}
                        {/* <p className="uppercase font-black text-center">Problem Statement</p> */}
                        <img src={olu_pic} className="w-full rounded-lg object-cover h-[400px] max-sm:w-full" />



                        <p className='mt-10'>
                            I’ve always been a self-starter with an endless curiosity for design, software, and technology. Over the years, that curiosity has led me to master tools like 3ds Max, Maya, Premiere Pro, After Effects, Photoshop, Figma, Nuke, AutoCAD, and more. This constant drive to learn shapes how I approach design—breaking down complex problems and crafting thoughtful, innovative solutions.</p>
                        <br />
                        <p className=''> My experience spans web and desktop application design, development, and tools built for creatives and professionals. Whether it’s designing intuitive user experiences or tackling intricate workflows, I bring a unique mix of creativity and technical expertise to every project.</p>

                        <p>

                            I’m all about making things work beautifully, whether it’s crafting a seamless web app or solving a tricky design challenge.
                        </p>

                        <hr className="border-t border-gray-300 my-10 -mx-24 max-sm:mx-0" />

                        <div className='flex flex-row gap-3 mt-3 mb-10 max-sm:flex-col'>
                            <img src={onCamera} className="w-1/2 rounded-lg object-cover  max-sm:w-full" />
                            <img src={onCamera2} className="w-1/2 rounded-lg object-cover  max-sm:w-full" />

                        </div>


                        <p>Outside of work, I spend some time on the weekends volunteering at my local church, where I serve as a cameraman and video editor.

                            I also lead the video production team for <a href='https:www.openheavenscalgary.ca' target='blank' referrerPolicy='none' className='underline'>Open Heavens Calgary,</a> the largest free gospel event in Western Canada, drawing over 5,000 attendees annually.
                            In this role, I oversee the creation of promotional content, stage intros for performing artists, and event-day visuals using tools like Adobe Premiere Pro and After Effects. I also film and edit videos displayed on projector screens during the event, ensuring a seamless and engaging experience for in-house and online attendees.
                        </p>

                        <hr className="border-t border-gray-300 my-10 -mx-24 max-sm:mx-0" />

                        <img src={atMountains} className="w-full rounded-lg object-cover mb-10 " />
                        <p>
                            When I'm not staring at a screen working, designing, watching movies or playing games, I like to spend some time in the beautiful Alberta outdoors. I find myself making a trip to Banff national part yearly, and the view, never gets old.
                        </p>




                    {/* </div> */}

                {/* </div> */}

            </div>





            <Footer />
        </div>
    )
}

export default About