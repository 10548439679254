import React, { useState } from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { BrowserRouter, Routes, Link, Route } from 'react-router-dom';
import unityLogo from "../assets/unityLogo.png";
import UnityHMI from "./UnityHMI";
import UnitySystemGraph from "./UnitySystemGraph";
import UnityUITK from "./UnityUITK";
import UnityDigitalTwins from "./UnityDigitalTwins";

const UnityProjects = () => {
    const [activePage, setActivePage] = useState("");
    const pages = {
        UnityUITK: <UnityUITK />,
        UnityHMI: <UnityHMI />,
        UnitySystemGraph: <UnitySystemGraph />,
        UnityDigitalTwins: <UnityDigitalTwins />,
    };


    return (



        <div className="bg-[#FAF9F5] justify-center items-center ">
            <MainHeader />
            <div className="flex flex-col  justify-center items-center  mx-10" id="IntroSection">
                <div className="flex flex-col w-full bg-[#FAF9F5] mt-10 fixed z-20 top-0 justify-center items-center ">
                    <h1 className="text-6xl   top-20 px-64 mt-10  font-black max-sm:text-4xl">Unity Projects</h1>
                    {/* <p>The World is a better place with more creators in it</p> */}
                    <div className=" flex-wrap hidden justify-center items-center mt-5 gap-5  z-50 top-20 mb-10 max-sm:gap-2 max-sm:mb-2">

                        <button className={`flex  flex-row bg-white border rounded-md hover:bg-black hover:text-white font-semibold w-fit p-2 items-center cursor-pointer ${activePage === "UnityHMI" ? " text-red-500" : "bg-gray-200"}`}
                            onClick={() => setActivePage(UnityHMI) ? "text-red-500" : "bg-gray-200"}>
                            <img src={unityLogo} className="h-[30px] w-[30px] mr-2" />
                            <p className="w-fit">Human Machine Interface (H.M.I.)</p>
                        </button>

                        <button className="flex  flex-row bg-white border rounded-md hover:bg-black hover:text-white font-semibold w-fit p-2 items-center cursor-pointer" onClick={() => setActivePage(UnityUITK)}>                        <img src={unityLogo} className="h-[30px] w-[30px] mr-2" />
                            <p className="w-fit">UI Toolkit</p>
                        </button>

                        <button className="flex flex-row bg-white border rounded-md hover:bg-black hover:text-white  font-semibold w-fit p-2 items-center cursor-pointer" onClick={() => setActivePage(UnitySystemGraph)}>
                            <img src={unityLogo} className="h-[30px] w-[30px] mr-2" />
                            <p className="w-fit">SystemGraph</p>
                        </button>

                        <button className="flex  flex-row bg-white border rounded-md hover:bg-black hover:text-white font-semibold w-fit p-2 items-center cursor-pointer" onClick={() => setActivePage(UnityDigitalTwins)}>                          <img src={unityLogo} className="h-[30px] w-[30px] mr-2" />
                            <p className="w-fit">Unity Digital Twins</p>
                        </button>
                    </div>

                </div>
                <div className="w-[50%] mt-[250px] max-sm:w-full max-sm:mt-[360px]">
                    <p className="mb-3">Unity is a leading platform for creating and operating interactive, real-time 3D (RT3D) content.
                        In 2020, software built with the Unity engine was running on more than 1.5 billion devices. Unity's game engine is used to create mobile games, and in 2020, apps made with Unity were downloaded more than 3 billion times per month.
                        Renowned for its versatility, Unity powers a vast range of applications, from video games to automotive HMI, film production, architectural visualization, and beyond. Its powerful tools enable creators across industries to bring their ideas to life, offering unmatched flexibility in design, simulation, and storytelling. </p>

                    <p>As a Senior Product Designer at Unity, my role focuses on designing innovative creator tools—products and systems that empower developers, designers, and artists to achieve their creative visions with greater efficiency and ease. Collaborating with cross-functional teams that include product managers, developers, researchers, and user experience experts, I drive the end-to-end design process, ensuring our tools align with user needs and industry standards.</p>

                    <br />

                    <p>Here are some of the projects I've worked on or currently working while at Unity.</p>

                    <h2 className="text-xl font-bold uppercase mb-3 mt-8">UI Toolkit</h2>
                    <p>Unity's UI Builder is a powerful creator tool that enables designers and developers to build intuitive and responsive user interfaces with ease using familiar web-based and CSS workflows. <br />

                        Combining flexibility with the real-time capabilities of Unity, UI Toolkit allows for seamless integration of custom UI components across a variety of platforms. This tool empowers creators to design, prototype, and deploy high-quality, interactive interfaces, enhancing user experiences across games, applications, and beyond.</p>
                    {/* <br/> */}
                    {/* <p>My work on the UI Toolkit project includes </p> */}
                    <a href="/" className="underline mr-5 text-blue-500 ">View site</a>
                    <Link to="/" className="underline text-blue-500 ">View Details</Link>


                    <h2 className="text-xl font-bold uppercase mb-3 mt-8">Human Machine Interface (HMI)</h2>
                    <p>
                        Unity HMI is a powerful tool that can help you create stunning, user-friendly interfaces for your automotive projects. With Unity HMI, you can easily integrate system and sensor data to create visually stunning and data-informed HMI experiences. This tool is designed to help you enhance your automotive interface design and create seamless integration of system and sensor data.
                    </p>
                    <a href="/" className="underline mr-5 text-blue-500 ">View site</a>
                    <Link to="/" className="underline text-blue-500 ">View Details</Link>

                    <h2 className="text-xl font-bold uppercase mb-3 mt-8">Systemgraph</h2>
                    <p>
                        SystemGraph is a composition tool used to represent digital twin emulation of systems. By emulating all parts of a system and separating function as nodes, this tool is able to represent the schematics of a design in an intuitive way for engineers. SystemGraph is made for system developers. The functionality of SystemGraph is useful to a wide spectrum of users, such as Sensor manufacturing engineers, Robotic systems developers, IOT systems developers, Autonomous vehicle engineers, Car systems developers etc.
                    </p>
                    <a href="/" className="underline mr-5 text-blue-500 ">View site</a>
                    <Link to="/" className="underline text-blue-500 ">View Details</Link>

                    <h2 className="text-xl font-bold uppercase mb-3 mt-8">Unity Digital Twins</h2>
                    <p>
                        The power of digital twins comes from connecting real-world assets with real-world data, so you can better visualize them. Digital twins enable cross-functional teams to collaboratively design, build, test, deploy and operate complex systems in interactive and immersive ways. They help companies understand the past, view present conditions, and prevent future problems. They inform decision-making through sales and marketing insights, analysis, 3D visualization, simulation, and prediction.
                    </p>
                    <a href="/" className="underline mr-5 text-blue-500 ">View site</a>
                    <Link to="/" className="underline text-blue-500 ">View Details</Link>





                </div>


            </div>

            <hr className="my-10" />

            <div className="" id="detailsSection">

                {/* <UnityHMI/> */}
                {/* <div className="mt-4">{pages[activePage]}</div> */}
                <div className="mt-4">                {activePage}
                </div>



            </div>






            <div className=" flex flex-row mx-14 justify-between max-sm:mx-2">


                <Link to="/Project2">


                    <ArrowLeft size={60} className="" />
                </Link>

                {/* <Link to="/Project4"> */}
                <ArrowRight size={60} className="opacity-35" />
                {/* </Link> */}

            </div>

            <Footer />

        </div>
    );


}

export default UnityProjects;