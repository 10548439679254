import React from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { BrowserRouter, Routes, Link, Route } from 'react-router-dom';

const Project4 =() => {

    return (
        <div className="bg-[#FAF9F5]">
        <MainHeader/>
        <h1 className="mt-20">Project 4</h1>


        <div className=" flex flex-row mx-14 justify-between max-sm:mx-2">

                
                <Link to="/Project3">
            <ArrowLeft size={60} className="" />
            </Link>

            <Link to="/Project5">
            <ArrowRight size={60} />
            </Link>
                
                </div>

        <Footer/>
    
    </div>
    );


}

export default Project4