import logo from './logo.svg';
import './App.css';
import MainHeader from './components/MainHeader';
import ProjectCards from './components/ProjectCards';
import project from '../src/assets/project.jpg';
import projectimage1 from '../src/assets/projectImage1.png';
import Project1 from './pages/Project1';
import Project2 from './pages/Project2';
import Project3 from './pages/UnityProjects';
import UnityProjects from './pages/UnityProjects';
import Project4 from './pages/Project4';
import Project5 from './pages/Project5';
import { BrowserRouter, Routes, Link, Route, Router } from 'react-router-dom';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import UnityHMI from './pages/UnityHMI';
import ScrollToTop from './components/ScrollToTop';



function App() {


  return (
    // <BrowserRouter>
    <div className="bg-[#FAF9F5]">
              <ScrollToTop/>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Project1" element={<Project1 />} />
        <Route path="/Project2" element={<Project2 />} />
        {/* <Route path="/ProjectTwo" component={ProjectTwo}/> */}
        <Route path="/UnityProjects" element={<UnityProjects/>} />
        <Route path="/Project4" element={<Project4 />} />
        <Route path="/Project5" element={<Project5 />} />
        <Route path="/About" element={<About/>}/>
        <Route path="/UnityHMI" element={<UnityHMI/>}/>


      </Routes>

      


    </div>
    // </BrowserRouter>
  );
}

export default App;
