import React from "react";
import { ArrowLineUpRight } from "@phosphor-icons/react";
import { BrowserRouter, Routes, Link, Route } from 'react-router-dom';
import ProjectTwo from "../pages/Project2";



const ProjectCards = ({ image, projectname, description, button1, button2, button1link, button2link }) => {


    return (


        // <div className="flex flex-row">

        <div className="w-[500px] h-auto flex flex-col max-sm:w-full ">

            <Link to={button1link}>
                <img src={image} alt="" className="object-cover h-[400px] border hover:opacity-80 hover:border-black " />

            </Link>


            <p className="text-lg font-bold mt-2">{projectname}</p>
            <p className="text-sm h-[15%] text-ellipsis overflow-hidden ">{description}</p>
            <div className="flex flex-row gap-4 text-black mt-3">

                <Link to={button1link}>
                    <button className=" bg-[#ED6A0D]  hover:bg-[#ed6a0dcb] px-3 py-2  text-sm rounded-lg">
                        {button1}

                    </button>
                </Link>

                <a href={button2link} target="_blank" rel="noopener noreferrer">
                    <button className="flex items-center space-x-2 bg-[#FDE2D5] hover:bg-[#fde2d5d0]  px-3 py-2 text-sm rounded-lg"> <span>{button2}</span>
                        <ArrowLineUpRight />

                    </button>
                </a>
            </div>



            {/* </div> */}

        </div>


    );
}


export default ProjectCards;