import React from 'react'
import { BrowserRouter, Routes, Link, Route } from 'react-router-dom';
import MainHeader from '../components/MainHeader'
import ProjectCards from '../components/ProjectCards'
import Footer from '../components/Footer'
import project from '../assets/project.jpg';
import projectimage1 from '../assets/projectImage1.png';
import unity2 from '../assets/unity2.jpg';
import projectImage2 from '../assets/projectImage2.png';
import { FigmaLogo, Book, Pencil, Gear, Atom, Kanban } from '@phosphor-icons/react';
import grocery from '../assets/grocery.png';
import uitk from '../assets/uitk.avif';
import uitk_gif from '../assets/uitk_gif.gif';
import HMI1 from '../assets/HMI1.gif';
import HMI2 from '../assets/HMI2.avif';
import Systemgraph from '../assets/Systemgraph.avif';

const Home = () => {
    return (
        <div className='bg-gray-950 text-white'>
            <MainHeader />

            <section className='px-36 flex pt-24 bg-gray-900 h-[600px] items-center justify-center max-sm:px-10' id='HeaderSection'>
                <div className='w-full  text-gray-300 flex flex-row'>
                    <div className='flex flex-col w-[50%] max-sm:w-full '>
                        {/* <p className=' text-sm justify-between mb-2'>Olumide Olajide</p> */}
                        {/* <p className='font-black text-6xl max-sm:text-4xl'>Senior <p className='font-black text-6xl max-sm:text-4xl  bg-gradient-to-r from-amber-300 to-purple-600 via-orange-600 bg-clip-text text-transparent' >Product Designer</p> and Front-End Developer</p> */}

                        <p className='font-black text-6xl max-sm:text-4xl'>Hi, My name is <p className='font-black text-6xl max-sm:text-4xl uppercase  bg-gradient-to-r from-amber-300 to-purple-600 via-orange-600 bg-clip-text text-transparent' >Olumide Olajide</p></p>

                        <p className=' mt-2 text-sm'>I've been a Product designer and UX Engineer for over a decade, with expertise in simplifying  intricate problems to create seamless, user-centered solutions. <br /> Current Role: Senior product designer, <a href='https://www.unity.com' target="_blank" rel="noopener noreferrer" className='underline cursor-pointer'>Unity technologies</a> </p>

                        <a href="https://drive.google.com/file/d/1-m8R3eOyeObzpZVmLTexpCob5LN99tXc/view?usp=sharing" target="_blank" rel="noopener noreferrer" className='w-fit'>
                            <button className=' outline text-white px-12 h-[45px] mt-3  hover:bg-[#ED6A0D] hover:text-black outline-white '>View Resume</button></a>



                    </div>

                </div>


            </section>

            <section className=' px-36 pt-12 max-sm:px-10' id='mainBody'>
                <div className='w-[70%] m-auto max-sm:w-full'>

                <h1 className='text-6xl font-bold mb-5 max-sm:text-3xl'>
                    Skills
                </h1>
                <div className='mb-12 flex flex-wrap gap-5 text-center items-center '>
                    <p className='outline w-fit py-2 px-5 gap-2 flex-row flex  hover:bg-black hover:text-white max-sm:w-full '><Pencil size={20} />UI Design</p>
                    <p className='outline w-fit py-2 px-5 gap-2 flex-row flex  hover:bg-black hover:text-white max-sm:w-full'><Gear size={20} />UX Design</p>
                    <p className='outline w-fit py-2 px-5 gap-2 flex-row flex  hover:bg-black hover:text-white max-sm:w-full'><Kanban size={20} />Product Design</p>

                    <p className='outline w-fit py-2 px-5 gap-2 flex-row flex  hover:bg-black hover:text-white max-sm:w-full'><Book size={20} />User Reseach</p>
                    <p className='outline w-fit py-2 px-5 gap-2 flex-row flex  hover:bg-black hover:text-white max-sm:w-full'><Gear size={20} />UX Design</p>


                    <p className='outline w-fit py-2 px-5 gap-2 flex-row flex  hover:bg-black hover:text-white max-sm:w-full'><FigmaLogo size={20} />Interaction Design</p>
                    <p className='outline w-fit py-2 px-5 gap-2 flex-row flex  hover:bg-black hover:text-white max-sm:w-full'><Atom size={20} />Front-end Development</p>

                </div>
                {/* <p className='mb-12'>I have some of my projects listed below for your viewing. </p> */}

                <hr className='border-t border-gray-300 mb-12' />


                <h1 className='text-6xl font-bold mb-12 max-sm:text-3xl'>
                    Featured Projects
                </h1>



                <div className='flex  flex-col w-full gap-5 mb-10  max-sm:flex-col max-sm:justify-center max-md:justify-center' id='#projects'>

                    {/* <ProjectCards
                        image={projectimage1}
                        projectname={"No-Q"}
                        description={"No-Q is an online marketplace that enables outdoor event goers browse products of nearby vendors and place orders from their mobile devices. I took multiple roles for this project, including lead designer, product owner and front-end developer."}
                        button1={"Read Casestudy"}
                        button1link={"/Project1"}
                        button2={"View Site"}
                        button2link="https://no-q.netlify.app/"
                    /> */}

                    {/* <ProjectCards
                        image={projectImage2}
                        projectname={"A.I. Powered E-commerce Site"}
                        description={"Personalized shopping experience enhancing engagement, recommendations, and user satisfaction."}
                        button1={"Read Casestudy"}
                        button1link='/Project2'
                        button2={"View Site"}
                        // button2link={""}
                    /> */}

                    {/* <ProjectCards
                        image={unity2}
                        projectname={"UNITY Technologies"}
                        description={"Unity is a platform for creating interactive, real-time 3D applications with capacities for rendering, simulation, and collaboration. As Senior product designer, I designed tools for creators, designers, developers and artists to bring their visions to life."}
                        button1={"View Unity Projects"}
                        button1link='/UnityProjects'
                        button2={"View Site"}
                        button2link="https://www.unity.com"
                    /> */}

                    {/* <ProjectCards
                        image={grocery}
                        projectname={"Grocery Subscription App"}
                        description={"Convenient grocery subscriptions with flexible management and personalized recommendations."}
                        button1={"Read Casestudy"}
                        button1link='/Project4'
                        button2={"View Site"}
                        button2link={""}
                    /> */}

                    <h2 className='text-4xl font-bold  max-sm:text-3xl'>
                        U.I. Toolkit
                    </h2>
                    <p>
                        UI Toolkit (UITK) is a flexible creator tool that enables developers and designers to build custom runtime UI systems within Unity. UI Toolkit is built on web technologies (CSS, XML, and JavaScript-like C# bindings) and is designed to be extensible and customizable. It provides a powerful and flexible way to create, style, and animate UI elements in Unity.
                    </p>
                    <a href=' https://unity.com/features/ui-toolkit ' target='_blank' referrerPolicy='none' className='underline hover:opacity-80'> Learn more 
                   

                    <img src={uitk_gif} className='w-full mt-5  object-cover' alt='UI Toolkit'
                    /></a>

                    <h2 className='text-4xl font-bold mt-16  max-sm:text-3xl'>
                        Human Machine Interface (H.M.I.)
                    </h2>
                    <p>
                        Unity HMI is a robust solution for designing high-performance, user-centric interfaces for automotive applications. It enables seamless integration of system and sensor data, allowing designers and developers to craft visually compelling and data-driven HMI experiences.
                    </p>
                    <Link to='/UnityHMI' className='underline hover:opacity-80'> Learn more

                        <img src={HMI1} className='w-full mt-5  object-cover' alt='Human Machine Interface (H.M.I.) 1'/>
                        <img src={HMI2} className='w-full  object-cover' alt='Human Machine Interface (H.M.I.) 2'/>
                    </Link>

                    <h2 className='text-4xl font-bold mt-16  max-sm:text-3xl'>
                        Unity Systemgraph
                    </h2>
                    <p>
                        SystemGraph is a composition tool used to represent digital twin emulation of systems. By emulating all parts of a system and separating function as nodes, this tool is able to represent the schematics of a design in an intuitive way for engineers.
                    </p>
                    <a href='https://unity.com/blog/industry/emulate-sensors-and-mechatronics-systems-with-unity-systemgraph' target='_blank' rel='noreferrer' className='underline hover:opacity-80'> Learn more
                        <div className='w-full'>
                            <img src={Systemgraph} className='w-full mt-3 object-cover' alt='SystemGraph' />
                        </div></a>

                        <hr className='border-t border-gray-300 mt-24' />




                    <h1 className='text-6xl font-bold mt-24 max-sm:text-3xl'>
                        Case Studies
                    </h1>
                    <div className='flex  flex-row flex-wrap  w-full gap-5 mb-10  max-sm:flex-col max-sm:justify-center max-md:justify-center' id='#projects'>
                        <ProjectCards
                            image={projectimage1}
                            projectname={"No-Q"}
                            description={"No-Q is an online marketplace that enables outdoor event goers browse products of nearby vendors and place orders from their mobile devices. I took multiple roles for this project, including lead designer, product owner and front-end developer."}
                            button1={"Read Casestudy"}
                            button1link={"/Project1"}
                            button2={"View Site"}
                            button2link="https://no-q.ca/"
                        />

                    </div>



                </div>

                </div>





            </section>

            <Footer />
        </div>
    )
}

export default Home