import React from 'react';
import HMI1 from '../assets/HMI1.png';
import HMI2 from '../assets/HMI2.png';
import HMI3 from '../assets/HMI3.avif';
import HMI_Persona1 from '../assets/HMI_Persona1.jpg';
import HMI_Persona2 from '../assets/HMI_Persona2.jpg';
import HMI_Persona3 from '../assets/HMI_Persona3.jpg';
import HMI_Persona4 from '../assets/HMI_Persona4.jpg';
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';
import HMI4 from '../assets/HMI4.avif';
import HMI_Design from '../assets/HMI_Design.png';

const UnityHMI = () => {
  return (

    <div className='bg-gray-950 text-white pb-16'>
      <MainHeader />
      <div className='relative flex mt-16 flex-col w-full  '>
        <img src={HMI2} className='object-cover h-[450px] w-full  ' />

        {/* <p className="text-6xl font-semibold  text-white absolute outline flex  items-center text-center inset-5 justify-center max-sm:text-4xl">Unity Human Machine Interface(H.M.I.)</p> */}


      </div>
      <div className='flex flex-row items-center justify-center mt-10'>
        <p className='text-white font-bold text-center text-4xl'>Driving Innovation: <br /> <span className='text-[#85827A]'>Empowering</span> Automotive <br /> <span className='text-[#85827A]'>HMI Design</span> with Unity
        </p>

      </div>

      <div className='flex flex-row items-start justify-center gap-20 mt-10 mx-10 max-sm:flex-col max-sm:gap-10'>

        <div className='flex flex-col items-start justify-center w-1/3 max-sm:w-full'>
          <p className='font-bold'>HMI projects </p>
          <p>HMI projects are complex and require a lot of time and effort to develop. Unity HMI is a powerful tool that can help you create stunning, user-friendly interfaces for your automotive projects. With Unity HMI, you can easily integrate system and sensor data to create visually stunning and data-informed HMI experiences. This tool is designed to help you enhance your automotive interface design and create seamless integration of system and sensor data.</p>
        </div>
        <div className='flex flex-col items-start justify-center w-1/3 max-sm:w-full max-sm:items-center'>
          <ul>
            <li>Role: Senior Product Designer </li>
            {/* <li>Role: Senior Product Designer </li>
            <li>Role: Senior Product Designer </li> */}
          </ul></div>

        <div className='flex flex-row w-1/3 max-sm:w-full'>
          <div className='flex flex-wrap gap-3 justify-center text-black w-full'>
            <p className='font-semibold rounded-full w-fit  bg-[#fcdbca] border p-3'>User research  </p>

            <p className='font-semibold rounded-full w-fit  bg-[#fcdbca] border p-3'>UI Design </p>
            <p className='font-semibold rounded-full  w-fit   bg-[#fcdbca] border p-3'>Interactive prototyping  </p>
            <p className='font-semibold rounded-full   w-fit  bg-[#fcdbca] border p-3'>Competitive analysis  </p>
            {/* <p className='font-semibold rounded-full  w-fit   bg-[#fcdbca] border p-3'>User research  </p>
            <p className='font-semibold rounded-full  w-fit   bg-[#fcdbca] border p-3'>User research  </p> */}
          </div>
        </div>

      </div>

      <h1 className='text-4xl font-bold bg-gray-900 text-white px-8 py-20 text-center mt-10'>Project Overview</h1>

      <div className='flex flex-row items-center justify-center gap-20 mt-10 mx-10 max-sm:flex-col max-sm:gap-10'>

        <div className='flex flex-col items-start justify-center w-1/3 max-sm:w-full'>
          <p className='font-bold'>Defining the problem </p>
          <p>As vehicles become increasingly advanced, the role of Human-Machine Interfaces (HMI) has grown exponentially. Modern HMI systems are shifting towards more graphical, interactive, and 3D-driven designs to meet user expectations and match the capabilities of next-generation vehicles. However, this rapid evolution highlights several critical challenges in the current state of HMI development:</p>

          <div className='flex flex-col items-start justify-center max-sm:w-full mt-2 max-sm:items-center'>
            <ul className='list-disc'>
              <li>Outdated Development Approaches: Despite the shift to more sophisticated visuals and interactivity, many HMIs are still developed using traditional workflows and tools that cannot fully support modern design needs. This limits the potential for innovation and scalability. </li>
              <li>Fragmented and Inefficient Workflows: Current HMI design and development processes are often disjointed, requiring designers and developers to switch between multiple tools, formats, and platforms. This leads to inefficiencies, miscommunication, and longer development cycles. </li>
              <li>Limited Real-Time Feedback: Iterative design in HMI development lacks tools for real-time feedback, making it harder to refine designs quickly and ensure they align with user expectations.</li>
              <li>Integration Challenges: Incorporating complex system and sensor data into HMI design often results in significant technical hurdles, requiring custom solutions that increase development time and costs.</li>
            </ul></div>

        </div>


        <img src={HMI1} className='object-cover h-[450px] w-1/3 max-sm:w-full' />



      </div>

      <h1 className='text-4xl font-bold bg-gray-900 text-white px-8 py-20 text-center mt-10'>User research and Insights</h1>

      <div className='flex flex-row items-center justify-center gap-20 mt-10 mx-10 max-sm:flex-col max-sm:gap-10'>

        <div className='flex flex-col items-start justify-center mb-10 w-1/2 max-sm:w-full'>
          <p className='font-bold'>Understanding User Needs </p>
          <p>To create an impactful tool for HMI designers and developers in the automotive industry, it was vital to delve deeply into their workflows, challenges, and goals. By conducting user interviews, surveys, and contextual inquiries, I uncovered several critical pain points that shaped the design process:</p>

          <div className='flex flex-col items-start justify-center max-sm:w-full mt-2 max-sm:items-center'>
            <ul className='list-decimal '>
              <li>Managing Complex Data
                HMI designers face significant challenges in integrating and displaying vast amounts of system and sensor data effectively. Essential information, such as fuel efficiency, speed, and navigation, must be accessible in real-time without overwhelming the interface or the user. Designers struggled to balance functionality with clarity, often grappling with cluttered screens that detracted from usability. </li>

              <li>Achieving Visual Consistency
                While technical accuracy is essential, designers emphasized the importance of aesthetics. They sought tools that allowed them to create visually consistent interfaces that reflect brand identity and enhance the overall user experience. Maintaining a cohesive look and feel across different vehicle models and systems was a recurring challenge. </li>

              <li> Addressing Customization Needs
                The automotive industry demands flexibility. Designers needed tools that could accommodate diverse requirements for various vehicle models and system architectures. The ability to easily tailor designs for specific applications and adjust parameters dynamically was a top priority for users.</li>

              <li>Enhancing Prototyping Efficiency
                Many existing tools were too slow and cumbersome for rapid prototyping. Designers expressed frustration with inefficiencies, such as lengthy iteration cycles and the inability to visualize real-time data in their prototypes. They wanted a solution that would accelerate their workflow and provide immediate feedback on how data-driven elements would appear in the final product.</li>
            </ul></div>

          <p className='font-bold mt-10'>Crafting a Targeted Solution
          </p>
          <p>Armed with these insights, I designed a tool tailored to the unique needs of HMI designers and developers. The solution addressed their pain points by:</p>

          <ul className='list-disc '>
            <li>Simplifying the integration and presentation of complex system data. </li>

            <li>Providing robust customization options for a wide range of applications. </li>

            <li> Streamlining prototyping workflows with real-time feedback and intuitive controls.</li>

            <li>Enabling the creation of visually appealing, brand-aligned interfaces that elevate the user experience.</li>
          </ul>




        </div>


        {/* <img src={HMI1} className='object-cover h-[450px] w-1/3 ' /> */}



      </div>
      <img src={HMI3} className='object-cover h-full w-full' />

      <h1 className='text-4xl font-bold bg-gray-900 text-white px-8 py-20 text-center mt-10'>Personas</h1>
      <div className='flex flex-col gap-5 my-5 justify-center items-center  max-sm:mx-10'>
        <p className='w-1/3 max-sm:w-full'>When designing Human-Machine Interfaces (HMI), understanding the people who will use them is just as important as the technology itself. The Persona section highlights key user types, providing insight into their needs, behaviors, and challenges. These personas range from automotive engineers fine-tuning vehicle interfaces to everyday drivers who expect a seamless and intuitive experience.<br />

          Each persona is shaped by user research, industry insights, and real-world use cases, ensuring that the design decisions prioritize usability, efficiency, and accessibility. By keeping these users in focus, we can bridge the gap between complex system functionality and an intuitive experience, making interactions smoother and more effective for everyone.</p>
      </div>

      <div className='flex flex-row gap-5 justify-center items-center flex-wrap max-sm:mx-10'>

        <img src={HMI_Persona1} className='w-1/3 max-sm:w-full'  />
        <img src={HMI_Persona2} className='w-1/3 max-sm:w-full' />
        <img src={HMI_Persona3} className='w-1/3 max-sm:w-full' />
        <img src={HMI_Persona4} className='w-1/3 max-sm:w-full' />    
      </div>


      <h1 className='text-4xl mb-10 font-bold bg-gray-900 text-white px-8 py-20 text-center mt-10'>Design</h1>
      <div className='mx-24'>
      <img src={HMI_Design} className='object-cover ' />

      </div>


      <h1 className='text-4xl font-bold bg-gray-900 text-white px-8 py-20 text-center mt-10'>Impact and Outcomes</h1>
      <div className='mx-24 mt-5 flex  flex-row max-sm:flex-col gap-5 justify-center items-center'>
        <div className='flex flex-col gap-3 items-start mx-auto justify-center w-1/3 max-sm:w-full'>
        <h2 className='text-2xl  font-bold'>A landmark partnership with automotive leader</h2>
        <p>Unity partners with Mercedes-Benz AG to power infotainment systems across its vehicle portfolio.</p>
        <a href='https://unity.com/blog/industry/screens-are-the-new-horsepower-leading-luxury-auto-brand-innovates-with-unity' target='_blank' referrerPolicy='none'  className='underline  '>Learn more</a>
        </div>
        <img src={HMI4} className='w-1/2 max-sm:w-full' />
       

      </div>














    </div>
  )
}

export default UnityHMI